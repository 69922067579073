import React from "react"
import { Link } from "gatsby"


import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import "../styles/style.sass"

const IndexPage = () => (
  <div>
    <SEO title="Home" />
    <Layout>
    </Layout>
  </div>
)

export default IndexPage
